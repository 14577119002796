.pathSelect {
  padding: 5px 0px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.inputHelper{
  padding: 0px!important;
}

.button {
  height: 100%;
  width: 100%;
  font-size: 12px!important;
}

.overlay {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  border: #bbb7b7 1px solid;

  background-color: white;

  .file {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: darkgrey 1px solid;

    &:hover {
      background-color: aliceblue;
    }

    .fileType{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 5px;
    }
  }

}

.caption {
  font-size: 12px;
}

.inputPath {
  font-size: 12px!important;
}

.error {
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 12px;
  height: 30px;
  width: 100%;
  background-color: red;
  color: white;
}
