.group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: #bbb7b7 1px solid;
  padding: 15px 5px 5px 5px;
  min-height: 50px;

  .title {
    font-size: 14px;
    position: absolute;
    background-color: white;
    top: -11px;
    color: #bbb7b7;
  }
}