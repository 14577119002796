.jobListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.jobListHeader {
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  display: flex;
  width: 100%;
  height: 50px;
  background-color: white;
  font-size: 13px;
  box-shadow: 0px 2px 5px grey;
  z-index: 100;
}

.addButton {
  font-size: 18px;
  color: grey;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: black;
    transform: scale(1.2);
  }
}

.jobList {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: auto;
}
