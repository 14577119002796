.repositorySettings {
  position: relative;

  .form-label {
    font-size: 12px;
    margin: 0;
  }

  .checkRepo {
    position: absolute;
    font-size: 25px;
    right: 10px;
    top: -7px;
    cursor: pointer;
    transition: .3s;

    &.checked {
      color: green;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}