.jobCardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 100%;
  height: 50px;
  border-bottom: #bbb7b7 1px solid;
  padding: 10px;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;

  &:hover {
    background-color: aliceblue;
  }
}

.editButton {
  font-size: 14px;
  color: grey;
  cursor: pointer;
  transition: .3s;

  &:hover {
    color: black;
    transform: scale(1.2);
  }
}

.jobCardCaption {
  display: flex;
  flex-direction: column;

  .url {
    font-size: 9px;
    color: darkgrey;
  }
}
